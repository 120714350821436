import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Layout from '../layouts';

export default (props) => {
  const product = props.pageContext.product;
  console.log(props)
  return (
  <Layout nav="shop" >
  <Row>
    <Col xs={12} sm={12} md={4} mdOffset={1}>
      <div className="tab-content mb-10 send-bt">
        <div className="pro-large-img tab-pane active" style={{textAlign: 'center'}}>
          <img src={product['Product Shots'] && product['Product Shots'][0].url} alt="" />
        </div>
      </div>
      {/*<div className="pro-thumb-img-slider slick-initialized slick-slider"><button type="button" className="arrow-prev slick-arrow" style={{display: 'block'}}><i className="fa fa-angle-left"></i></button></div>*/}
    </Col>
    <Col xs={12} sm={12} md={6}>
      <div className="product-details section send-bt">
        <h2 className="title" style={{fontSize: '24px'}}>{product.Name}</h2>
        <div className="short-desc section" style={{paddingTop: '10px'}}>
          <h5 className="pd-sub-title">${product['Retail Price'].toFixed(2)}</h5>
          <p>{(product.Description || '').replace(/<\/?[^>]+(>|$)/g, "")}</p>
        </div>
        <div className="quantity-cart section"></div>
        {/*product.Status === 'Available' ?
          <a className="btn btn-gold snipcart-add-item" href="#" style={{width: '100%'}}
            data-item-id = {product.SKU}
            data-item-metadata = {`{"type": "${product.Type}"}`}
            data-item-price = {product['Retail Price']}
            data-item-url = "https://www.pourmgmt.com/products"
            data-item-image = {product['Product Shots'] && product['Product Shots'][0].url}
            data-item-name = {product.Name}
          >
            <button style={{marginTop: '5px', width: '300px'}}>Add to cart - ${product['Retail Price']}</button>
          </a>
            :
          <div>
            <button className="snipcart-add-item" style={{marginTop: '5px', width: '100%'}}>Sold Out - ${product.Price}</button>
          </div>*/}
      </div>
    </Col>
  </Row>
  </Layout>
  )
}
